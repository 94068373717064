@import './styles/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Fira Code', monospace;
}

details summary::after {
  content: '+';
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  transition: transform 0.2s;
}

details[open] summary::after {
  content: '-';
}

details summary {
  list-style: none;
  outline: none;
  cursor: pointer;
}

.fp-warning,
.fp-watermark {
  display: none;
}

#modal-content {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
}
